/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap');
$primary-color: #FF4655;
$secondary-color: #26194D;
$background-color: white;
$svg-background: 'https://i.imgur.com/3PcHQUT.png';

p {
  color: white;
}

.container {
  position: relative;
}
.left {
  float: left;
  width: max-content;
  height: 100%;
}
.right {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
}
@media only screen and (max-width: 2000px) {
  .right {
    width: 100%;
  }
}
.land-time {
  position: absolute;
  z-index: 1100;
  right: 100px;
  top: 0;
  font-size: 50px;
}
.lines-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 800;
}
.line {
  stroke-width: 1.5;
  opacity: 75%;
  cursor: pointer;
}
.molly {
  position: absolute;
  z-index: 1000;
  color: red;
  cursor: pointer;
}
.molly-outline {
  position: absolute;
  z-index: 900;
  color: red;
}
.plant {
  position: absolute;
  z-index: 1000;
  color: blue;
  cursor: pointer;
}
.plant-outline {
  position: absolute;
  z-index: 900;
  color: blue;
}
.molly-hover {
  position: absolute;
  width: 960px;
  margin-left: 15px;
  margin-top: 15px;
  z-index: 1100;
}
.dev-circle {
  color: yellow;
  position: absolute;
  z-index: 9999;
}
.map-button {
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid white;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: black;
  text-align: center;
  transition: all 0.2s;
  cursor: pointer;
  font-size: 15px;
}
.map-button:hover {
  color: white;
  background-color: black;
}
a:link {
  color: #5c99d6;
}
a:visited {
  color: #a679d2
}

.selectedForLine fa-icon {
  box-shadow: 0 0 0 3px yellow;
}


// --------------------------
// --------------------------
// --------------------------


mat-icon {
  color: white;
  align-self: center;
  padding-right: 20px;
  cursor: pointer;
}

// --------------------------
// --------------------------
// --------------------------

.btn-container {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}

.btn {
  /* Clean style */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  color: var(--button-text-color);
  cursor: pointer;
  /* Clean style */

  --button-text-color: var(--background-color);
  --button-text-color-hover: var(--button-background-color);
  --border-color: #7D8082;
  --button-background-color: #ece8e1;
  --highlight-color: #ff4655;
  --button-inner-border-color: transparent;
  --button-bits-color: var(--background-color);
  --button-bits-color-hover: var(--button-background-color);

  position: relative;
  padding: 8px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  transition: all .15s ease;
  min-width: 175px;
}

.btn::before,
.btn::after {
  content: '';
  display: block;
  position: absolute;
  right: 0; left: 0;
  height: calc(50% - 5px);
  border: 1px solid var(--border-color);
  transition: all .15s ease;
}

.btn::before {
  top: 0;
  border-bottom-width: 0;
}

.btn::after {
  bottom: 0;
  border-top-width: 0;
}

.btn:active,
.btn:focus {
  outline: none;
}

.btn:active::before,
.btn:active::after {
  right: 3px;
  left: 3px;
}

.btn:active::before {
  top: 3px;
}

.btn:active::after {
  bottom: 3px;
}

.btn__inner {
  position: relative;
  display: block;
  padding: 20px 30px;
  background-color: var(--button-background-color);
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 1px var(--button-inner-border-color);
}

.btn__inner::before {
  content: '';
  display: block;
  position: absolute;
  top: 0; left: 0;
  width: 2px;
  height: 2px;
  background-color: var(--button-bits-color);
}

.btn__inner::after {
  content: '';
  display: block;
  position: absolute;
  right: 0; bottom: 0;
  width: 4px;
  height: 4px;
  background-color: var(--button-bits-color);
  transition: all .2s ease;
}

.btn__slide {
  display: block;
  position: absolute;
  top: 0; bottom: -1px; left: -8px;
  width: 0;
  background-color: var(--highlight-color);
  transform: skew(-15deg);
  transition: all .2s ease;
}

.btn__content {
  position: relative;
}

.btn:hover {
  color: var(--button-text-color-hover);
}

.btn:hover .btn__slide {
  width: calc(100% + 15px);
}

.btn:hover .btn__inner::after {
  background-color: var(--button-bits-color-hover);
}

.btn--light {
  --button-background-color: var(--background-color);
  --button-text-color: var(--highlight-color);
  --button-inner-border-color: var(--highlight-color);
  --button-text-color-hover: #ece8e1;
  --button-bits-color-hover: #ece8e1;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
